.Main-div {
  display: flex;
  width: auto;
  justify-content: center;
}
.Main-leftDiv {
  width: 7%;
  position: sticky;
  top: 0px;
  height: 100vh;
}
.Main-mainDiv {
  width: 86%;

  min-height: 150vh;
}

.Main-rightDiv {
  width: 7%;
  position: sticky;
  top: 0px;
  height: 100vh;
}
.Main-footer {
  height: 100px;
}
.Main-header {
  height: 210px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}
