.Header-holdsButtonsDiv {
  margin-bottom: 2%;
  min-height: 80px;
  min-width: 50px;
  display: flex;
  position: relative;
  top: 10%;
}
.Header-holdsButtonsDiv:hover {
  cursor: pointer;
}
.Header-mainDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
}
.Header-LogosDiv {
  display: flex;
  flex-direction: column;
  width: min-content;
  text-align: center;
}
.st4 {
  fill: #771b1e;
  stroke: #771b1e;
}
.st4:hover {
  fill: #ff353c;
  stroke: #ff353c;
}
.on4 {
  fill: #ff353c;
  stroke: #ff353c;
}
.oncand {
  fill: #ff353c;
}
.stcand:hover {
  fill: #ff353c;
}
.stcand:focus {
  fill: #ff353c;
}
.stcand {
  fill: #771b1e;
}
