.Portfolio-RecipesImage-div {
  width: 60%;
  background: #ff7067;
  height: min-content;
  position: relative;
}
.Portfolio-RecipesImage {
  display: block;
  opacity: 0.6;
  width: 100%;
}
.Portfolio-RecipesImage:hover {
  opacity: unset;
}
.Portfolio-holder-div {
  display: flex;
  justify-content: space-around;
  position: relative;
}
